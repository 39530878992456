.wrapper {
  width: 16px;
  height: 16px;
  border: 1px solid #8f9ea9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}

.activeWrapper {
  border: 1px solid;
}

.checked {
  width: 12px;
  height: 12px;
  // background-color: #0087ce;
  border: 1px solid #0087ce;
  border-radius: 2px;
}
