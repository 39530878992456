@import "../../styles/colors.scss";

.wrapper {
  margin-bottom: 20px;
}

.label {
  font-family: Nunito;
  font-weight: 500;
  font-size: 16px;
  color: $textColor;
  margin-bottom: 8px;
}

