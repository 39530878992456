@import '../../../../../../styles/colors.scss';

.wrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dbe0f0;
  width: 100%;
  // height: 56px;
  padding: 17px 0;
  padding-left: 16px;
  position: relative;
}

.selected {
  background: rgba(0, 135, 206, 0.03);
}

.nameTextWrapper {
  font-family: Nunito;
  font-weight: 700;
  font-size: 16px;
  color: $textColor;
  opacity: 0.8;
  margin-left: 24px;
}

.dateText {
  width: 200px;
  flex-shrink: 0;
}

.img {
  position: absolute;
  cursor: pointer;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

.title {
  padding-right: 30px;
  opacity: 1;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}