$mainBlue: #008bd2;
$mainGreen: #46ac33;
$textColor: #353b3f;
$lightText: #8f9ea9;
$white: #ffffff;
$dillyBlue: #344a59;
$wizardGrey: #505d68;
$cottonBallGrey: #f6f8fe;
$luceaBlue: #7ab4df;
$lenticularOreGreen: #70b4ab;
$greenBonnet: #87b392;
$vaguelyViolet: #dbe0f0;
$rubyRed: #de3745;
