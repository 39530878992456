@import "../../../../styles/colors.scss";

.btnWrapper {
  display: flex;
}

.addApiKeyBtn {
  background-color: #008BD2;
  color: white;
  border-radius: 4px;
  width: 172px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Raleway;
  font-weight: 600;
  margin-right: 20px;
  border: none;
  cursor: pointer;
}

.removeBtnActive {
  width: 172px;
  height: 40px;
  border: 1px solid #DE3745;
  color: #de3745;
  background-color: rgba(222, 55, 69, 0.05);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Raleway;
  font-weight: 600;
  cursor: pointer;
}

.removeBtnInactive {
  width: 172px;
  height: 40px;
  border: 1px solid $lightText;
  color: $lightText;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Raleway;
  font-weight: 600;
  opacity: 0.4;
}

.icon {
  margin-right: 11px;
}