.wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  background: url('../../assets/images/adminLoginBG.svg') 50% 50%/cover no-repeat;
  background-color: #f5f7fb;
}

.formWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 672px;
  padding: 40px 30px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 1px 1px 8px rgba(40, 54, 64, 0.04);
  border-radius: 16px;
}

.form {
  width: 100%;
}

.logo {
  width: 236px;
  height: 30px;
  margin-bottom: 50px;
}
