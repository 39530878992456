@import "../../styles/colors.scss";

.contentWrapper {
  background: #ffffff;
  box-shadow: 1px 1px 8px rgba(40, 54, 64, 0.04);
  border-radius: 16px;
  width: 100%;
  height: calc(100vh - 114px);
  padding: 20px;
  // position: relative;
  overflow-y: scroll;
}
