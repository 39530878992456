@import '../../styles/colors.scss';

.title {
  color: $textColor;
  font-family: Raleway;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 16px;
}

.tableInfoWrapper {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e9ecf1;
  font-family: Nunito;
  margin-bottom: 40px;
}

.rowWrapper {
  display: flex;
  border-bottom: 1px solid #e9ecf1;
}
.lastRowWrapper {
  display: flex;
}

.rowTitle {
  width: 246px;
  border-right: 1px solid #e9ecf1;
  // border-bottom: 1px solid #e9ecf1;
  font-weight: 700;
  font-size: 16px;
  color: #8f9ea9;
  padding: 16px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.rowInfo {
  font-weight: 500;
  font-size: 16px;
  color: #353b3f;
  padding: 16px;
  flex-grow: 1;
}