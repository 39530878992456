
.btn_wrapper {
  border: none;
  outline: none;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.back {
  margin-right: 8px;
}

.next {
  margin-left: 8px;
}