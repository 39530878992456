@import "../../styles/colors.scss";

.input_wrapper {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-bottom: 20px;
  position: relative;

  @media (max-width: 576px) {
    width: 100%;
  }
}

.label {
  font-family: Nunito;
  font-weight: 500;
  font-size: 16px;
  color: $textColor;
  margin-bottom: 8px;
}

.customInput {
  height: 44px;
  background: $cottonBallGrey;
  border: 1px solid $vaguelyViolet;
  border-radius: 4px;
  padding: 12px 10px;
  font-family: Nunito;
  font-weight: 500;
  font-size: 14px;
  color: $textColor;
  outline: none;
  width: 100%;

  &::placeholder {
    font-family: Nunito;
    font-weight: 500;
    font-size: 14px;
    color: rgba(143, 158, 169, 0.6) !important;
  }

  &:focus-visible {
    outline: none;
    border-color: $mainBlue;
    box-shadow: 1px 1px 12px rgba(0, 139, 210, 0.08);
  }
}

.customInputWithWarning {
  background: rgba(222, 55, 69, 0.05);
  border: 1px solid #de3745;
  box-shadow: 1px 1px 12px rgba(222, 55, 69, 0.08);
  outline: none;
}

.warningImg {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 14px;
  right: 14px;
}

.warningImgWithSelect {
  right: 32px;
}

.errorMessage {
  position: absolute;
  top: 105%;
  font-family: Nunito;
  font-weight: 400;
  font-size: 14px;
  color: $rubyRed;
}

.labelWithWarning {
  color: $rubyRed;
}

.fullWidthInput {
  width: 100%;
}