.svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.backWrapper {
  // cursor: pointer;
  width: 24px;
  height: 24px;
  position: relative;
  border-radius: 4px;
}

.backItemWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.backText {
  font-family: Raleway;
  font-weight: 700;
  margin-left: 12px;
  text-transform: uppercase;
  font-size: 14px;
}
