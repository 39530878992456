@font-face {
  font-family: Raleway;
  src: url("../assets/fonts/Raleway-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Raleway;
  src: url("../assets/fonts/Raleway-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: Raleway;
  src: url("../assets/fonts/Raleway-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Nunito;
  src: url("../assets/fonts/Nunito-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: Nunito;
  src: url("../assets/fonts/Nunito-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Nunito;
  src: url("../assets/fonts/Nunito-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Nunito;
  src: url("../assets/fonts/Nunito-ExtraBold.ttf");
  font-weight: 800;
}
@font-face {
  font-family: Nunito;
  src: url("../assets/fonts/Nunito-Black.ttf");
  font-weight: 900;
}
@font-face {
  font-family: Dosis;
  src: url("../assets/fonts/Dosis-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: Dosis;
  src: url("../assets/fonts/Dosis-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Dosis;
  src: url("../assets/fonts/Dosis-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Dosis;
  src: url("../assets/fonts/Dosis-ExtraBold.ttf");
  font-weight: 800;
}
