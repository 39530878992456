@import "../../../../styles/colors.scss";

.wrapper {
  width: 672px;
  // height: 260px;
  background-color: #fff;
  box-shadow: 1px 1px 8px rgba(40, 54, 64, 0.04);
  border-radius: 16px;
  position: relative;
  padding: 30px;
  min-height: 300px;
  max-height: 500px;
  overflow: overlay;
}

.headerWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.title {
  color: $textColor;
  font-size: 20px;
  font-family: Raleway;
  font-weight: 700;
}

.img {
  cursor: pointer;
}
