.wrapper {
  display: flex;
}

.mainBodyWrapper {
  width: 100%;
  background-color: #f5f7fb;
  padding: 27px 20px 20px;
  height: 100vh;
  overflow-y: scroll;
}
