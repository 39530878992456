@import "../../styles/colors.scss";

.noTokenContentWrapper {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imgWrapper {
  display: block;
  margin: 0 auto 36px;
}

.noTokenTitle {
  font-family: Raleway;
  font-weight: 600;
  font-size: 18px;
  color: $textColor;
  margin-bottom: 20px;
}

.noTokenSubtitle {
  color: rgba(53, 59, 63, 0.6);
  font-size: 16px;
  font-family: Nunito;
  font-weight: 400;
  margin-bottom: 30px;
}

.noTokenBtn {
  margin: 0 auto;
}

.addApiKeyBtn {
  background-color: #008BD2;
  color: white;
  border-radius: 4px;
  width: 172px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Raleway;
  font-weight: 600;
  margin-right: 20px;
  border: none;
  margin: 0 auto;
  cursor: pointer;
}

.icon {
  margin-right: 5px;
}