@import '../../styles/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
}

.labelText {
  font-size: 16px;
  color: $textColor;
  margin-bottom: 10px;
  font-family: Nunito;
  font-weight: 500;
}

.textArea {
  resize: none;
  width: 100%;
  height: 100px;
  outline: none;
  border: 1px solid $vaguelyViolet;
  border-radius: 4px;
  padding: 12px 10px;
  background-color: $cottonBallGrey;
  font-family: Nunito;
  font-weight: 500;
  font-size: 14px;
  color: $textColor;

  &::placeholder {
    font-family: Nunito;
    font-weight: 500;
    font-size: 14px;
    color: rgba(143, 158, 169, 0.6);
  }
}

.input_wrapperWithWarning {
  margin-bottom: 48px;
  position: relative;
}

.warningImg {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 38%;
  right: 14px;
  transform: translateY(50%);
}

.errorMessage {
  position: absolute;
  top: 105%;
  font-family: Nunito;
  font-weight: 400;
  font-size: 14px;
  color: $rubyRed;
}

.labelWithWarning {
  color: $rubyRed;
}

.customInputWithWarning {
  background: rgba(222, 55, 69, 0.05);
  border: 1px solid $rubyRed;
  box-shadow: 1px 1px 12px rgba(222, 55, 69, 0.08);
  outline: none;

  &:focus-visible {
    outline: none;
  }
}