@import "../../styles/colors";

.main_title {
  text-align: center;
  max-width: 1110px;
  margin: 0 auto;
  span {
    font-family: Raleway;
    font-weight: 700;
    font-size: 36px;
    letter-spacing: 2px;
    color: $textColor;
    text-align: center;
  }

  @media (max-width: 576px) {
    padding: 0 15px;
  }
}
