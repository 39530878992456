@import "../../styles/colors.scss";

.titleText {
  font-family: Nunito;
  font-weight: 700;
  font-size: 16px;
  color: $lightText;
  // margin-left: 24px;
  text-align: center;
  // background-color: #fff;
  border: none !important;
}
.itemText {
  font-family: Nunito;
  font-weight: 500;
  font-size: 16px;
  color: $textColor;
  // opacity: 0.8;
  // margin-left: 24px;
  text-align: center;
  // background-color: #fff;
  border: none !important;
}

.btnWrapper {
  // margin-bottom: 20px;
}

.qwe {
  border: none !important;
  // .k-grid-container {
  //   table {
  //     ::-webkit-scrollbar {
  //       width: 4px;
  //       // border-radius: 2px;
  //       // background-color: rgba(143, 158, 169, 0.4);
  //     }

  //     ::-webkit-scrollbar-track {
  //       background: rgba(143, 158, 169, 0.1);
  //     }

  //     ::-webkit-scrollbar-thumb {
  //       background: rgba(143, 158, 169, 0.4);
  //       border-radius: 2px;
  //     }
  //   }
  // }
  * {
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgba(143, 158, 169, 0.1);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba(143, 158, 169, 0.4);
      border-radius: 2px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(143, 158, 169, 0.1);
    }
  }
}

.customInputWrapper {
  div {
    input {
      width: 210px !important;
    }
  }
}

.customInput {
  height: 32px;
  background: $cottonBallGrey;
  border: 1px solid $vaguelyViolet;
  border-radius: 4px;
  padding: 12px 10px;
  font-family: Nunito;
  font-weight: 500;
  font-size: 14px;
  color: $textColor;
  outline: none;
  width: 200px;

  &::placeholder {
    font-family: Nunito;
    font-weight: 500;
    font-size: 14px;
    color: rgba(143, 158, 169, 0.6) !important;
  }

  &:focus-visible {
    outline: none;
    border-color: $mainBlue;
    box-shadow: 1px 1px 12px rgba(0, 139, 210, 0.08);
  }
}