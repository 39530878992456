.valueContainer {
  display: flex;
  // align-items: center;
  font-size: 14px;
  border: none !important;
  flex-direction: column;
  background-color: #fff;
  cursor: pointer;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 576px) {
    flex-shrink: 0;
  }
}

.qwe {
  button {
    display: none;
  }

  &:focus {
    box-shadow: none !important;
  }
}

%textBase {
  font-family: Raleway;
  font-weight: 600;
  text-decoration: none;
}

.name {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: #353b3f;
}

.address {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #8f9ea9;
}

.link {
  @extend %textBase;
  font-size: 14px;
  color: #353b3f;
  text-decoration: none;
  cursor: pointer;
  outline: none !important;
}

.ewq {
  [role="tooltip"].popup-content {
    padding: 20px 10px;
    width: 250px !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  }
}

.linksWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px;
  background-color: #fff;
  width: 200px;
  border: 1px solid black;
  border-radius: 6px;
  position: absolute;
  top: 65px;
  right: 0;

  @media (max-width: 576px) {
    top: 125px;
  }
}

.container {
  position: absolute;
  top: 66px;
  background-color: #fff;
}

.initials {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #588c73;
  background-color: rgba(88, 140, 115, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #588c73;

  @media (max-width: 576px) {
    flex-shrink: 0;
  }
}
