@import "./styles/fonts.scss";
@import "~@progress/kendo-theme-default/dist/all.scss";

tbody {
  background-color: #fff;
}
table {
  thead {
    tr {
      height: 36px !important;
    }
  }
}
thead {
  tr {
    th {
      border: none;
      background-color: #fff;
    }
    th.k-table-th.k-sorted {
      background-color: rgba(143, 158, 169, 0.05);
    }
  }
}

th {
  border-left: none !important;
}

table tr {
  border-bottom: 1px solid #dbe0f0 !important;
  height: 56px !important;
}

table {
  tbody {
    tr {
      &:last-child {
        border-bottom: none !important;
      }
    }
  }
}

table tr:hover {
  background-color: rgba(143, 158, 169, 0.05) !important;
}

.k-grid tr:last-child {
  border-bottom: none !important; /* remove bottom border from last row */
}

.k-grid table {
  border-collapse: collapse !important;
}

[data-is-selected="selected"] {
  &[data-env="awl"] {
    background-color: rgba(70, 172, 51, 0.1) !important;
  }
  &[data-env="bk"] {
    background-color: rgba(90, 150, 64, 0.1) !important;
  }
  &[data-env="sensis"] {
    background-color: rgba(0, 135, 206, 0.1) !important;
  }
}

.k-icon.k-i-sort-desc-small,
.k-icon.k-i-sort-asc-small {
  color: green; /* set the color to red, replace with your preferred color */
}

.react-datepicker__triangle {
  display: none;
}

.k-list-content {
  font-family: Nunito !important;
  * {
    font-family: Nunito !important;
  }
}

.k-input-value-text {
  font-family: Nunito !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  @import "./styles/fonts.scss";
}

.mainWrapper {
  margin-top: 66px;
}

%viersenWrapper {
  background-color: #F5F7FB;
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.loginViersenMainWrapper {
  @extend %viersenWrapper;
}

.viersenMainWrapper {
  @extend %viersenWrapper;
  margin-top: 66px;
  height: calc(100vh - 66px);
}

[role='tooltip'].popup-content {
  width: 250px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}