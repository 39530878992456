@import "../../../../../../../../styles/colors.scss";

.label {
  display: block;
  font-family: Nunito;
  font-weight: 500;
  font-size: 16px;
  color: $textColor;
  margin-bottom: 8px;
}

.status {
  margin-top: 8px;
  margin-bottom: 24px;
}

.statusWrapper {
  width: 383px;
  height: 44px;
  background: rgba(143, 158, 169, 0.1);
  border-radius: 8px;
  padding: 4px;
  display: flex;
}

%tab {
  width: 50%;
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  font-family: Nunito;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.tabText {
  @extend %tab;
  color: $textColor;
}

.activeTab {
  @extend %tab;
  color: #46ac33;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(41, 82, 225, 0.15);
  border-radius: 8px;
}

.inactiveTab {
  @extend %tab;
  color: $white;
  background: rgba(143, 158, 169, 0.5);
  box-shadow: 0px 2px 8px rgba(41, 82, 225, 0.15);
  border-radius: 8px;
}