.wrapper {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 1px 1px 8px 0px rgba(40, 54, 64, 0.04);
  border-radius: 16px;
  padding: 20px 20px;
}

.text {
  font-family: Nunito;
  font-weight: 400;
  font-size: 16px;
  color: #353b3f;
  margin-bottom: 30px;
  text-align: justify;
}
