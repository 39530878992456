@import "../../../../../../../../styles/colors.scss";

.wrapper {
  margin-bottom: 150px;
}

.label {
  display: block;
  font-family: Nunito;
  font-weight: 500;
  font-size: 16px;
  color: $textColor;
  margin-bottom: 8px;
}

.calendarItemWrapper {
  // width: 100%;
  // min-height: 300px;
  border: 1px solid #dbe0f0;
  border-radius: 8px;
  display: flex;
  flex-grow: 0;
}

.calendarWrapper {
  width: 382px;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  padding-top: 10px;
  border-right: 1px solid rgba(143, 158, 169, 0.2);
}

.selectedDatesWrapper {
  // flex-shrink: 0;
  flex-grow: 1;
  padding: 14px 20px;
}

.datesWrapper {
  // width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}

.selectedDateWrapper {
  width: 154px;
  height: 32px;
  padding: 6px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Nunito;
  font-weight: 500;
  font-size: 14px;
  color: $textColor;
  background: rgba(0, 139, 210, 0.05);
  border: 1px solid #008bd2;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 10px;
}

.removeIcon {
  cursor: pointer;
}

%calendarText {
  font-family: Nunito;
  font-weight: 500;
  font-size: 14px;
  color: $textColor;
}

.selectDateText {
  @extend %calendarText;
  text-align: center;
  margin-bottom: 8px;
}

.monthNameText {
  @extend %calendarText;
  margin: 0 12px;
}

.monthNameWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.arrowImg {
  cursor: pointer;
}

.dayOfWeeksWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

.dayOfWeekText {
  font-family: Nunito;
  font-weight: 500;
  font-size: 12px;
  color: $lightText;
  // flex-basis: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.calendarWeekWrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
}

.day {
  // border-top: 1px solid rgba(143, 158, 169, 0.2);
  // border-right: 1px solid rgba(143, 158, 169, 0.2);
  border: 0.5px solid rgba(143, 158, 169, 0.2);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Nunito;
  font-weight: 500;
  font-size: 14px;
  color: $textColor;
  flex-shrink: 0;
  // flex-grow: 0;
  // flex-basis: 1;
  width: 54.57px;
}

.firstWeekDays {
  border-top: 1px solid rgba(143, 158, 169, 0.2);
}

.lastWeekDays {
  border-bottom: none;
}

.cornerDay {
  border-bottom-left-radius: 8px;
}

.lastWeekDay {
  border-right: none;
}

.inActiveDay {
  background-color: rgba(143, 158, 169, 0.07);;
  color: rgba(53, 59, 63, 0.5);
}

.isWeekend {
  background-color: rgba(143, 158, 169, 0.07);
}

.currentDay {
  color: #0087CE;
}

.selectedDate {
  background: rgba(0, 135, 206, 0.1);
  border: 0.5px solid #0087CE;
}

.firstDayOfLastWeek {
  border-bottom-left-radius: 4px;
}