@import "../../styles/colors.scss";

.input_wrapper {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-bottom: 20px;
  position: relative;

  @media (max-width: 576px) {
    width: 100% !important;
  }
}

.input_wrapperWithWarning {
  margin-bottom: 48px;
}

.label {
  font-family: Nunito;
  font-weight: 500;
  font-size: 16px;
  color: $textColor;
  margin-bottom: 8px;
}

.labelWithWarning {
  color: $rubyRed;
}

.customInput {
  height: 44px;
  background: $cottonBallGrey;
  border: 1px solid $vaguelyViolet;
  border-radius: 4px;
  padding: 12px 10px;
  font-family: Nunito;
  font-weight: 500;
  font-size: 14px;
  color: $textColor;
  outline: none;

  &::placeholder {
    font-family: Nunito;
    font-weight: 500;
    font-size: 14px;
    color: rgba(143, 158, 169, 0.6) !important;
  }

  &:focus-visible {
    outline: none;
    border-color: $mainBlue;
    box-shadow: 1px 1px 12px rgba(0, 139, 210, 0.08);
  }
}

.disabledCustomInput {
  caret-color: transparent;

  &:focus-visible {
    outline: none;
    border-color: $vaguelyViolet;;
  }
}

.customInputWithWarning {
  background: rgba(222, 55, 69, 0.05);
  border: 1px solid $rubyRed;
  box-shadow: 1px 1px 12px rgba(222, 55, 69, 0.08);
  outline: none;

  &:focus-visible {
    outline: none;
  }
}

.qweqweqweqqwe {
  height: 44px !important;
  background: $cottonBallGrey !important;
  border: 1px solid $vaguelyViolet !important;
  border-radius: 4px !important;
  padding: 12px 10px !important;
  font-family: Nunito !important;
  font-weight: 500;
  font-size: 14px !important;
  color: $textColor !important;
  padding-left: 72px !important;
  width: 100% !important;

  ::placeholder {
    font-family: Nunito !important;
    font-weight: 500;
    font-size: 14px !important;
    color: rgba(143, 158, 169, 0.6) !important;
  }

  &:focus-visible {
    outline: 1px solid $mainBlue;
  }
}

.asdasdasdasd {
  width: 60px !important;
  border: none !important;
  background: $cottonBallGrey !important;
  // border-right: 1px solid $vaguelyViolet;

  .selected-flag:hover,
  .selected-flag .open {
    background: $cottonBallGrey !important;
  }
  // &:hover{
  //   background: $cottonBallGrey !important;
  // }
}

.fullWidthInput {
  width: 100%;
}

.arrowImg {
  position: absolute;
  width: 12px;
  right: 10px;
  bottom: 18px;
}

.select_wrapper {
  position: absolute;
  top: 100%;
  z-index: 10;
  background-color: $white;
  box-shadow: 1px 1px 12px rgba(40, 54, 64, 0.08);
  width: 100%;
  max-height: 335px;
  overflow-y: scroll;
}

.detailList_select_wrapper {
  max-height: 335px;
  overflow-y: scroll;
  width: 335px !important;
  position: absolute;
  top: 110%;
  z-index: 10;
  background-color: $white;
  box-shadow: 1px 1px 12px rgba(40, 54, 64, 0.08);
}

.select_item {
  font-family: Nunito;
  font-weight: 500;
  font-size: 14px;
  color: $textColor;
  cursor: pointer;
  padding: 8px 10px;

  &:hover {
    background-color: rgba(143, 158, 169, 0.1);
  }
}

.last_select_item {
  margin-bottom: 0;
}

.errorMessage {
  position: absolute;
  top: 105%;
  font-family: Nunito;
  font-weight: 400;
  font-size: 14px;
  color: $rubyRed;
}

.warningImg {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 14px;
  right: 14px;
}

.warningImgWithSelect {
  right: 32px;
}
