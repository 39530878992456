@import "../../styles/colors.scss";

.wrapper {
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;

  @media (max-width: 576px) {
    margin-bottom: 24px;
    padding: 0 15px;
  }
}

.headerStepperWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.stepperNameWrapper {
  display: flex;
}

.stepNumberWrapper {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: Nunito;
  font-weight: 800;
  border-radius: 50%;
  margin-right: 10px;
}

.stepNumberWrapperInactive {
  color: $lightText !important;
  border: 1px solid $lightText !important;
}

.title {
  font-family: Nunito;
  font-weight: 800;
  font-size: 16px;
}

.stepperWrapper {
  width: 100%;
  display: flex;
}
