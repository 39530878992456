@import "../../styles/colors.scss";

.wrapper {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  transition: 1s linear;
}

.helperWrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0 15px;
}

.nonActiveWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.1px;
  background-color: #fff;
  transition: 1s linear;
}

.linkWrapper {
  position: absolute;
  top: 234px;
  left: 50%;
  transform: translateX(-50%);
}

%link {
  font-size: 16px;
  font-family: Raleway;
  font-weight: 600;
  text-align: center;

  a {
    text-decoration: none;
    color: $textColor;
  }
}

.anmelden {
  @extend %link;
  text-transform: uppercase;
  color: $textColor;
  text-decoration: none;
}

.link {
  @extend %link;
}

.linkWithMargin {
  @extend %link;
  margin-bottom: 60px;
}

.btnWrapper {
  position: absolute;
  left: 15px;
  bottom: 120px;
  width: calc(100% - 30px);

  button:nth-child(2) {
    margin-top: 16px;
  }
}
