@import "../../styles/colors.scss";

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 27px;
  height: 40px;
}

.title {
  text-transform: uppercase;
  font-size: 20px;
  color: $textColor;
  font-family: Raleway;
  font-weight: 700;
  letter-spacing: 1px;
}