.wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  // flex-shrink: 0;

  @media (max-width: 576px) {
    flex-shrink: 0;
  }
}

.checkBox {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  flex-shrink: 0 !important;
  flex-grow: 1;
}

.text {
  font-family: Nunito;
  font-weight: 500;
  font-size: 14px;
  color: #353b3f;
  cursor: pointer;
}
