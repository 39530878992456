.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-right: 45px;
}

.img {
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 38%;
}
