@import '../../../../../../styles/colors.scss';

.wrapper {
  // display: flex;
  // align-items: center;
  // flex-direction: column;
  border-bottom: 1px solid #dbe0f0;
  width: 100%;
  min-height: 56px;
  padding: 17px 0;
  padding-left: 16px;
}

.qwe {
  display: flex;
  align-items: center;
}

.selected {
  background: rgba(0, 135, 206, 0.03);
}

.itemsWrapper {
  margin-top: 16px;
}

.nameTextWrapper {
  font-family: Nunito;
  font-weight: 700;
  font-size: 16px;
  color: $textColor;
  opacity: 0.8;
  margin-left: 24px;
}

.titleWrapper {
  display: flex;
  align-items: center;
  padding-left: 40px;
  margin-bottom: 15px;
  :nth-child(2) {
    margin-left: 24px;
  }
}

.titleFromTo {
  font-family: Nunito;
  font-weight: 700;
  font-size: 15px;
  color: $textColor;
  opacity: 0.8;
  // margin-left: 24px;
  :nth-child(2) {
    margin-left: 24px;
  }
}

.houseNumberWrapper {
  display: flex;
  align-items: center;
  padding-left: 40px;
  margin-bottom: 10px;
  :nth-child(2) {
    margin-left: 24px;
  }
}

.houseNumber {
  font-family: Nunito;
  font-weight: 700;
  font-size: 15px;
  color: $textColor;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 116px;
}