@import "../../../../../../styles/colors.scss";

.wrapper {
  position: relative;
  margin-bottom: 28px;
  width: 420px;

  @media (max-width: 576px) {
    width: 100%;
  }
}

.input {
  width: 420px;
  height: 32px;
  background: #f6f8fe;
  border: 1px solid #dbe0f0;
  border-radius: 4px;
  font-family: Nunito;
  font-weight: 500;
  font-size: 14px;
  color: $textColor;
  padding: 6px 10px;

  ::placeholder {
    font-family: Nunito;
    font-weight: 500;
    font-size: 14px;
    color: rgba(143, 158, 169, 0.6);
  }
  &:focus {
    border-color: #008bd2;
    box-shadow: 1px 1px 12px rgba(0, 139, 210, 0.08);
  }
  &:focus-visible {
    outline: none !important;
    border-color: #008bd2;
    box-shadow: 1px 1px 12px rgba(0, 139, 210, 0.08);
  }

  @media (max-width: 576px) {
    width: 100%;
  }
}

.icon {
  position: absolute;
  top: 8px;
  right: 10px;
}
