@import "../../styles/colors";

.header {
  height: 66px;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: $white;
  z-index: 20;
  box-shadow: 0px 1px 1px rgba(79, 116, 143, 0.06);

  @media (max-width: 576px) {
    padding: 0 15px;
  }
}

.header_container {
  position: relative;
  height: inherit;
  // box-shadow: 0px 1px 1px rgba(79, 116, 143, 0.06);
  max-width: 1110px;
  margin: 0 auto;
}

.nav_container {
  // max-width: 1110px;
  // margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: inherit;

  @media (max-width: 576px) {
    max-width: 100%;
  }
}

.nav_absolute_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;

  @media (max-width: 576px) {
    display: none;
  }
}

.nav_absolute_container2 {
  position: absolute;
  top: 50%;
  right: -60px;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;

  @media (max-width: 576px) {
    display: none;
  }
}

.auth_links_container {
  display: flex;
  align-items: center;

  @media (max-width: 576px) {
    display: none;
  }
}

%link-font {
  font-family: Raleway;
  font-weight: 600;
  font-size: 12px;
  text-decoration: none;
}

.main_link {
  text-transform: uppercase;
  cursor: pointer;
  a {
    color: $textColor;
    @extend %link-font;
  }
}

.anmelden {
  @extend %link-font;
  text-transform: uppercase;
  cursor: pointer;
  color: $textColor;
}

.portal_link {
  margin-right: 60px;
}

.faq_link {
  margin-right: 60px;
}

.login_link {
  margin-right: 36px;
  a {
    @extend %link-font;
    text-decoration: none;
    color: $mainBlue;
  }
}

.burgerContainer {
  display: none;

  @media (max-width: 576px) {
    display: block;
    position: absolute;
    right: 0;
    top: 25px;
    width: 22px;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-end;
    cursor: pointer;
  }
}

.burgerWrapper {
  width: 100%;
  height: 15.5px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.long {
  top: 7px;
  height: 2px;
  width: 22px;
  border-radius: 2px;
  background-color: $textColor;

  position: absolute;
  opacity: 1;
  right: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  transform-origin: left center;
}

.short {
  bottom: 0;
  height: 2px;
  width: 12px;
  border-radius: 2px;
  background-color: $textColor;

  position: absolute;
  opacity: 1;
  right: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  transform-origin: left center;
}

.middle {
  top: 0;
  height: 2px;
  width: 16.5px;
  border-radius: 2px;
  background-color: $textColor;

  position: absolute;
  opacity: 1;
  right: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  transform-origin: left center;
}

.isMiddleOpened {
  width: 22px;
  transform: rotate(45deg);
  top: 0px;
  right: -2px;
}

.isLongOpened {
  width: 0%;
  opacity: 0;
}

.isShortOpened {
  width: 22px;
  transform: rotate(-45deg);
  top: 15px;
  right: -2px;
}

.ebwoLogo {
  height: 46px;
}
