@import '../../styles/colors.scss';

.checkbox_label {
  font-family: Nunito;
  font-weight: 500;
  font-size: 14px;
  color: $textColor;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  width: fit-content;
}

.checkBox {
  width: 18px;
  height: 18px;
  background-color: $lightText;
  border-radius: 2px;
  opacity: 0.2;
  cursor: pointer;
  z-index: 4;
  user-select: none;
  margin-right: 18px;
}

.checkBox_checked {
  background-color: $mainBlue;
  opacity: 1;
  position: relative;
  user-select: none;
}

.checkedImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  cursor: pointer;
}

.checkboxTitle {
  color: $mainBlue;
}