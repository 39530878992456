.detailBtn {
  width: 28px;
  height: 28px;
  background-color: #8f9ea9;
  border: 1px solid #8f9ea9;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-left: 40px;

  &:hover {
    background-color: #353b3f;
    border: 1px solid #353b3f;
    box-shadow: 1px 1px 20px rgba(40, 54, 64, 0.16);
  }
}