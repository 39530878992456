@import "../../../../../../styles/colors.scss";

.wrapper {
  padding: 20px;
  border-bottom: 1px solid rgba(144, 160, 183, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.textWrapper {
  display: flex;
  align-items: center;
}

.img {
  margin-right: 12px;
}

%title {
  font-family: Nunito;
  font-weight: 700;
  font-size: 16px;
}

.activeTab {
  @extend %title;
  color: $textColor;
}

.inactiveTab {
  @extend %title;
  color: $lightText;
}
