@import "../../../../../../styles/colors.scss";

.wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #dbe0f0;
  width: 100%;
  padding-left: 16px;
  cursor: pointer;
}

.nameTextWrapper {
  font-family: Nunito;
  font-weight: 700;
  font-size: 16px;
  color: $lightText;
  opacity: 0.8;
  margin-left: 24px;
  margin-right: 6px;
}

.arrowUp {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 5px 4px;
  border-color: transparent transparent #8f9ea9 transparent;
  margin-bottom: 3px;
}

.arrowDown {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  border-color: #8f9ea9 transparent transparent transparent;
}

.activeDown {
  border-color: rgba(0, 135, 206, 0.3) transparent transparent transparent;
}

.activeUp {
  border-color: transparent transparent rgba(0, 135, 206, 0.3) transparent;
}
