@import "../../styles/colors";

.footer_wrapper {
  height: 99px;
  max-width: 1110px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: 576px) {
    height: fit-content;
    padding: 20px 15px;
    flex-direction: column;
    align-items: center;
  }
}

.logo {
  margin-bottom: 22px;
  @media (max-width: 576px) {
    margin-bottom: 30px;
  }
}

.ebwoLogo {
  width: 90px;
}

.copyright {
  color: $wizardGrey;
  font-size: 12px;
  font-family: Raleway;
  margin-bottom: 26px;
  font-weight: 500;

  @media (max-width: 576px) {
    margin-bottom: 0;
  }
}

.nav_container {
  position: absolute;
  left: 50%;
  bottom: 26px;
  transform: translateX(-50%);
  display: flex;
}

.mobileNav_container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;
}

.nav_link {
  cursor: pointer;
  margin-right: 44px;

  a {
    font-family: Raleway;
    font-weight: 600;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: $textColor;
    text-decoration: none;
  }

  :last-child {
    margin-right: 0;
  }

  @media (max-width: 576px) {
    margin-right: 0;
  }
}

.faq_link {
  margin-right: 0;
}
