@import "../../styles/colors.scss";

.wrapper {
  width: 268px;
  height: 100vh;
  // height: 100%;
  // position: relative;
  flex-shrink: 0;
  box-shadow: 1px 0px 6px rgba(79, 116, 143, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // align-items: center;
  padding: 30px 20px 32px;
  overflow-y: auto;
}

.groupTitle {
  color: $lightText;
  font-family: Nunito;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
}

.navigation {
  // position: absolute;
  display: flex;
  flex-direction: column;
  // top: 130px;
  // left: 50%;
  // transform: translateX(-50%);
}

.link {
  width: 228px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $textColor;
  font-size: 16px;
  font-family: Nunito;
  font-weight: 500;
  text-decoration: none;
  border-radius: 8px;
  justify-content: flex-start;
  padding-left: 29px;
  margin-bottom: 8px;
  position: relative;

  &:hover {
    background-color: rgba(143, 158, 169, 0.05);
  }
}

.logo {
  // position: absolute;
  // top: 30px;
  // left: 50%;
  // transform: translateX(-50%);
  margin-bottom: 50px;
  text-align: center;
}

.logoutBtn {
  // position: absolute;
  // bottom: 32px;
  // left: 20px;
  // transform: translateX(-50%);
  font-size: 16px;
  color: $textColor;
  font-family: Nunito;
  font-weight: 500;
  display: flex;
  align-items: center;
  // justify-content: center;
  cursor: pointer;
}

.logoutIcon {
  margin-right: 16px;
}

.awlLogo {
  width: 80px;
}

.notification {
  position: absolute;
  // top: -5px;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ea7600;
  color: white;
  font-family: Nunito;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activeNotification {
  background-color: white;
  border: 1px solid #ea7600;
  color: #ea7600;
}
