@import "../../styles/colors.scss";

.input_wrapper {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-bottom: 20px;
  position: relative;

  @media (max-width: 576px) {
    width: 100% !important;
  }
}

.input_wrapperWithWarning {
  margin-bottom: 48px;
}

.fullWidthInput {
  width: 100%;
}

.label {
  font-family: Nunito;
  font-weight: 500;
  font-size: 16px;
  color: $textColor;
  margin-bottom: 8px;
}

.labelWithWarning {
  color: $rubyRed;
}

.errorMessage {
  position: absolute;
  top: 105%;
  font-family: Nunito;
  font-weight: 400;
  font-size: 14px;
  color: $rubyRed;
}

.warningImg {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 14px;
  right: 14px;
}

.warningImgWithSelect {
  right: 32px;
}

.arrowImg {
  position: absolute;
  width: 12px;
  right: 10px;
  // bottom: 18px;
  bottom: 40%;
  // transform: translateY(-50%);
}

.customInputWithWarning {
  background: rgba(222, 55, 69, 0.05);
  border: 1px solid #de3745;
  box-shadow: 1px 1px 12px rgba(222, 55, 69, 0.08);
  outline: none;
}