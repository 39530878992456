@import "../../styles/colors.scss";

.input_wrapper {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-bottom: 20px;
  position: relative;

  @media (max-width: 576px) {
    width: 100% !important;
  }
}

.input_wrapperWithWarning {
  margin-bottom: 48px;
}

.label {
  font-family: Nunito;
  font-weight: 500;
  font-size: 16px;
  color: $textColor;
  margin-bottom: 15px;
}

.labelWithWarning {
  color: $rubyRed;
}

.errorMessage {
  position: absolute;
  bottom: -30px;
  font-family: Nunito;
  font-weight: 400;
  font-size: 14px;
  color: $rubyRed;
}

.warningImg {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 8px;
  right: 14px;
}

.phoneInput_wrapper {
  height: 44px !important;
  background: $cottonBallGrey !important;
  border: 1px solid $vaguelyViolet !important;
  border-radius: 4px !important;
  padding: 12px 10px !important;
  font-family: Nunito !important;
  font-weight: 500;
  font-size: 14px !important;
  color: $textColor !important;
  padding-left: 72px !important;
  width: 100% !important;

  ::placeholder {
    font-family: Nunito !important;
    font-weight: 500;
    font-size: 14px !important;
    color: rgba(143, 158, 169, 0.6) !important;
  }

  &:focus-visible {
    outline: 1px solid $mainBlue;
  }

  input {
    width: 100% !important;
  }
}

.customInputWithWarning {
  background: rgba(222, 55, 69, 0.05) !important;
  border: 1px solid $rubyRed !important;
  box-shadow: 1px 1px 12px rgba(222, 55, 69, 0.08);
  outline: none;

  &:focus-visible {
    outline: none;
  }
}

.customBtn {
  width: 60px;
  background: $cottonBallGrey !important;
  border: none !important;
  border-right: 1px solid $vaguelyViolet !important;
  left: 1px;

  div {
    div {
      right: 12px !important;
    }
  }

  div:hover,
  div:focus {
    background-color: $cottonBallGrey !important;
  }
}

.customBtnWithError {
  background: rgba(222, 55, 69, 0.05) !important;
  div:hover,
  div:focus {
    background-color: rgba(222, 55, 69, 0.05) !important;
  }
}

.customContainer {
  display: flex !important;
  align-items: center !important;
  height: 30px;
}

.dropdown {
  border: none !important;
  box-shadow: 1px 1px 12px rgba(40, 54, 64, 0.08) !important;
}

.disabledCustomInput {
  caret-color: transparent;

  &:focus-visible {
    outline: none;
    border-color: $vaguelyViolet;
  }
}
