@import '../../../../styles/colors.scss';

.title {
  color: $textColor;
  font-family: Raleway;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 16px;
}

.wrapper {
  margin-bottom: 40px;
}