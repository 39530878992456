@import "../../../../styles/colors.scss";

.wrapper {
  margin-left: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 0;
  }
}

.title_wrapper {
  display: flex;
  margin-bottom: 14px;
  @media (max-width: 576px) {
    align-items: flex-start;
    
  }
}

.step {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: Nunito;
  font-weight: 700;
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;

  @media (max-width: 576px) {
    width: 18px;
    height: 18px;
    margin-right: 3px;
    font-size: 10px;
  }
}

.step_inactive {
  color: $lightText !important;
  border: 1px solid $lightText !important;
}

.title {
  font-family: Nunito;
  font-weight: 700;
  font-size: 16px;

  @media (max-width: 576px) {
    font-size: 14px;
    overflow-wrap: anywhere;
  }
}

.title_inactive {
  color: $lightText !important;
}

.border {
  width: 100%;
  height: 6px;
}

.withBorderRadius {
  border-radius: 2px;
}

.border_inactive {
  background-color: $lightText !important;
  opacity: 0.6;
}

.activeMobileStep {
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 1px;
  }
}

.firstElement {
  @media (max-width: 576px) {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
}

.lastActiveElement {
  @media (max-width: 576px) {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}

.firstInactiveElement {
  @media (max-width: 576px) {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
}

.lastElement {
  @media (max-width: 576px) {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}
