@import "../../styles/colors.scss";

.wrapper {
  margin: 0 0 36px;
  position: relative;
  min-height: 32px;

  h2 {
    text-transform: uppercase;
  }
}

.back {
  position: absolute;
  top: 7px;
  left: 0;
  // transform: translateY(-50%);
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 12px;
  }

  span {
    font-family: Raleway;
    font-weight: 600;
    font-size: 12px;
    color: $textColor;
  }

  @media (max-width: 576px) {
    left: 15px;
    img {
      margin-right: 0;
    }
  }
}
