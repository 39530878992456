@import "../../styles/colors.scss";

.wrapper {
  width: 550px;
  // height: 260px;
  background-color: #fff;
  box-shadow: 1px 1px 8px rgba(40, 54, 64, 0.04);
  border-radius: 16px;
  position: relative;
  padding: 30px;
}

.headerWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.title {
  color: $textColor;
  font-size: 20px;
  font-family: Raleway;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.imgWrapper {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: rgba(222, 55, 69, 0.1);
  position: relative;
}

.img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.closeIcon {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}

.confirmationText {
  color: $textColor;
  font-size: 14px;
  font-family: Nunito;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}
