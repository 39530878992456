@import "../../styles/colors.scss";

.wrapper {
  width: 1110px;
  margin: 0 auto;
  padding-top: 48px;
}

.helperWrapper {
  width: 790px;
  margin: 0 auto;
}

.shortDescription {
  font-family: Nunito;
  font-weight: 400;
  font-size: 16px;
  color: $textColor;
  margin-bottom: 16px;
}

.date {
  font-family: Nunito;
  font-weight: 500;
  font-size: 14px;
  color: $lightText;
  margin-bottom: 48px;
}

.img {
  width: 100%;
  height: 350px;
  margin-bottom: 48px;
  flex-shrink: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 8px;
}

.articleTextWrapper {
  margin-bottom: 60px;
  h2,
  h3,
  h4,
  h5 {
    font-family: Raleway;
    font-weight: 700;
    font-size: 24px;
    color: $textColor;
    margin-bottom: 30px;
  }
  p {
    font-family: Nunito;
    font-weight: 400;
    font-size: 20px;
    color: $textColor;
    margin-bottom: 30px;
  }
  strong {
    font-family: Nunito;
    font-weight: 700;
    font-size: 20px;
    color: $textColor;
  }
  img {
    width: 100% !important;
    margin-bottom: 20px;
  }
}
