@import "../../../../../../styles/colors.scss";

.wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #dbe0f0;
  width: 100%;
  padding-left: 30px;
  cursor: pointer;
}

.nameTextWrapper {
  font-family: Nunito;
  font-weight: 700;
  font-size: 16px;
  color: $lightText;
  opacity: 0.8;
  margin-left: 24px;
}

.dateText {
  width: 200px;
}