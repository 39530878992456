.wrapper {
  padding-top: 36px;
  background-color: #f5f7fb;
  padding-bottom: 30px;
}

.innerWrapper {
  max-width: 1110px;
  margin: 0 auto;
  padding-top: 48px;
}

.btnWrapper {
  text-align: center;
  margin-bottom: 80px;
}

.footerHelperWrapper {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
