@import "../../../../../../styles/colors.scss";

.wrapper {
  display: flex;
  box-shadow: 1px 1px 12px rgba(40, 54, 64, 0.08);
  border-radius: 8px;
}

.firstElement {
  flex-direction: column;
  grid-area: first;
  max-width: 445px;
  min-height: 300px;
}

.secondElement {
  flex-direction: row;
  grid-area: second;
  width: 635px;
}

.thirdElement {
  flex-direction: row;
  grid-area: third;
  width: 635px;
}

.firstPosImg {
  width: 100%;
  height: 45.5%;
  border-radius: 8px 8px 0 0;
  flex-shrink: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.nextPosImg {
  width: 255px;
  height: 100%;
  border-radius: 8px 0 0 8px;
  flex-shrink: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.firstPosTextWrapper {
  padding: 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.nextPosTextWrapper {
  padding: 10px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.title {
  font-family: Raleway;
  font-weight: 700;
  font-size: 20px;
  color: $textColor;
  margin-bottom: 8px;
}

.dateText {
  font-family: Nunito;
  font-weight: 500;
  color: $lightText;
  font-size: 14px;
  margin-bottom: 8px;
}

.description {
  font-family: Nunito;
  font-weight: 500;
  font-size: 14px;
  color: $textColor;
  margin-bottom: 16px;
  line-height: 20px;
  max-height: 60px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.readMoreWrapper {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.readMoreText {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  margin-right: 9px;
  color: #0087ce;
}
