@import "../../styles/colors";

.reg_wrap {
  position: absolute;
  height: 25px;
  right: -1px;
  top: 8px;
  cursor: pointer;
  box-shadow: 0px 1px 4px rgba(40, 54, 64, 0.04);
}

.img {
  box-shadow: 0px 1px 4px rgba(40, 54, 64, 0.04);
}
