.wrapper {
  position: absolute;
  // top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  overflow: auto;
}

.spinner {
  width: 100px;
  text-align: center;

  div {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.7s infinite ease-in-out both;
    margin-right: 5px;
  }
}

.spinner .spinner__item1 {
  animation-delay: -0.6s;
}

.spinner .spinner__item2 {
  animation-delay: -0.4s;
}

.spinner .spinner__item3 {
  animation-delay: -0.2s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
    opacity: 0;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
}
