@import "../../styles/colors";

.btn_link {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  a {
    font-family: Raleway;
    font-weight: 600;
    font-size: 12px;
    text-decoration: none;
    padding: 12px 11px;
    color: $white;
    border-radius: 4px;
  }
}
