@import "../../../../../../styles/colors.scss";

.topWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.title {
  font-family: Nunito;
  font-weight: 400;
  color: $textColor;
  font-size: 16px;
}

.copyText {
  display: flex;
  align-items: center;
  color: #0087ce;
  font-size: 16px;
  font-family: Nunito;
  font-weight: 400;
  cursor: pointer;

  img {
    margin-right: 8px;
  }
}

.tokenText {
  color: $textColor;
  font-size: 14px;
  font-family: Nunito;
  font-weight: 700;
  margin-bottom: 30px;
  overflow-wrap: break-word;
}

.copied {
  margin-bottom: 5px;
}

.lapm {
  width: 33px;
  height: 30px;
  margin-right: 12px;
}

.bottomText {
  margin-left: 12px;
  color: #8f9ea9;
  font-size: 16px;
  font-family: Nunito;
  font-weight: 400;
}
