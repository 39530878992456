.addHouseNumber {
  margin-bottom: 20px;
  font-family: Nunito;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
}

.btnWrapper {
  display: flex;
  justify-content: space-between;
}
