.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 26px 30px;
  grid-template-areas:
    "first second"
    "first third";
  margin-bottom: 170px;
}
