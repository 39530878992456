@import "../../../../../../styles/colors.scss";

.wrapper {
  display: flex;
  gap: 30px;
}

.img {
  width: 160px;
  height: 138px;
  flex-shrink: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.title {
  font-family: Raleway;
  font-weight: 700;
  font-size: 20px;
  color: $textColor;
  margin-bottom: 8px;
}

.dateText {
  font-family: Nunito;
  font-weight: 500;
  color: $lightText;
  font-size: 14px;
  margin-bottom: 8px;
}

.description {
  font-family: Nunito;
  font-weight: 500;
  font-size: 14px;
  color: $textColor;
  margin-bottom: 20px;
}

.readMoreWrapper {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.readMoreText {
  font-family: Raleway;
  font-weight: 600;
  font-size: 14px;
  margin-right: 9px;
  color: #0087ce;
}

.divider {
  background-color: rgba(143, 158, 169, 0.3);
  width: 100%;
  height: 1px;
  margin: 24px 0;
}
